import React from "react"
import queryString from "query-string"
import {useStaticQuery, graphql} from "gatsby"

import Card from "../../components/card"
import { FormFieldInput, FormFieldTextArea, FormFieldFile  } from "../../components/form-fields"

import ApplicationFormLogic from "../../components/application-form-logic"
import ApplicationFormContainer from "../../components/application-form-container"

export class CreativePlacemakingForm extends ApplicationFormLogic {
  render() {
    return (
      <ApplicationFormContainer
        data={this.props.data}
        state={this.state}
        closeModal={this.closeModal}
        handleSubmit={this.handleSubmit}
        handleSave={this.handleSave}
        >
        <Card sectionTitle="Project" sectionSubtitle="">
          <FormFieldInput
            labelText="Project Name"
            name="projectName"
            type="text"
            value={this.state.projectName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Neighborhood Name"
            name="neighborhoodName"
            type="text"
            value={this.state.neighborhoodName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Briefly describe the creative placemaking practice
                      or project, the community where it is located, and
                      who participates."
            name="projectDescription"
            value={this.state.projectDescription}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Describe the community challenge or opportunity
                      that is being addressed by the creative placemaking
                      practice, and its impact to-date."
            name="projectChallenge"
            value={this.state.projectChallenge}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="How have community members and residents been
                      meaningfully engaged in planning, creating, and
                      sustaining this work?"
            name="projectCommunity"
            value={this.state.projectCommunity}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="How does the practice support or leverage
                      existing cultural and community assets?"
            name="projectAssets"
            value={this.state.projectAssets}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="What innovations were required to begin and
                      continue this creative placemaking practice?"
            name="projectInnovations"
            value={this.state.projectInnovations}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="What is the longer-term vision for the creative
                      placemaking practice?"
            name="projectVision"
            value={this.state.projectVision}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />
        </Card>

        <Card sectionTitle="Media" sectionSubtitle={this.props.data.mediaSectionSubhead}>
          <FormFieldFile
            labelText="Supporting Photos"
            handleProcessFile={this.handleProcessFile}
            handleProcessFiles={this.handleProcessFiles}
            handleRemoveFile={this.handleRemoveFile}
            files={this.state.files}
            disabled={this.state.submitted}
            name="uploadPhotos"
            helpText="Only image files (.png, .jpg, etc.) are accepted. Images must be a maximum of 5MB in size."
          />

          <FormFieldInput
            labelText="Video Link"
            name="videoLink"
            type="text"
            value={this.state.videoLink}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            helpText="Link to a video hosted on a video-sharing service like Dropbox or YouTube."
          />
        </Card>

        <Card sectionTitle="Contact" sectionSubtitle="">
          <FormFieldInput
            labelText="Name"
            name="applicantName"
            type="text"
            value={this.state.applicantName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Organization"
            name="applicantOrganization"
            type="text"
            value={this.state.applicantOrganization}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Title"
            name="applicantTitle"
            type="text"
            value={this.state.applicantTitle}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Address"
            name="applicantAddress"
            type="text"
            value={this.state.applicantAddress}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldInput
            labelText="Phone Number"
            name="applicantPhone"
            type="text"
            value={this.state.applicantPhone}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Email"
            name="applicantEmail"
            type="text"
            value={this.state.applicantEmail}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="How did you learn about the opportunity to apply for this award?"
            name="applicantReferral"
            type="text"
            value={this.state.applicantReferral}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />
        </Card>
      </ApplicationFormContainer>
    );
  }
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "applications/creative-places.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  data.applicationType="creative-places"
  data.fields=[
   'projectName', 'neighborhoodName',
   'projectDescription', 'projectChallenge',
   'projectCommunity', 'projectAssets',
   'projectInnovations', 'projectVision',
   'videoLink',
   'applicantName', 'applicantOrganization', 'applicantTitle',
   'applicantAddress', 'applicantPhone', 'applicantEmail', 
   'applicantReferral'
  ]

  data.title="Creative Places Award"
  data.titleText="The John D. and Catherine T. MacArthur Foundation Creative Places Award"
  data.awardDescription="The John D. and Catherine T. MacArthur Foundation Creative Places Award recognizes projects in the Chicago region that leverage the power of the arts, culture, and creativity to serve a community’s interest, while driving a broader agenda of change, growth and transformation in a way that builds character and quality of place."
  data.awardLink="https://lisc-cnda.org/awards/creative-placemaking/"
  data.headerImagePath="applications/creative-places.jpg"
  data.headerImageLink="https://map.lisc-cnda.org/2019/cnda/urban-juncture-for-boxville"
  data.headerImageProject="Urban Juncture for Boxville"
  data.headerImageDescription=", a 2019 Creative Places Award winner"
  data.mediaSectionSubhead="Up to 10 photos or a video link can be included but are not required."
  data.optionalMedia=true

  return (
    <CreativePlacemakingForm
      data={data}
      uuid={queryString.parse(props.location.search).uuid}
  />
  )
}
